<template>
<AuthLayout
  :title="$router.currentRoute.value.params.barcode"
  :breadcrumbs="breadcrumbs"
>
<template #content>
  <div class="RequestPage">
    
    <div
      class="RequestPage__content"
      :style="`height: calc(100vh - ${sum([
        48, // HeaderLayout
        94, // SubHeaderLayout
        81, // RequestPage__footer
        24, // AuthLayout__wrap
      ])}px)`"
    >
    
      <div class="RequestPage__title">
        Request info
      </div>
      
      <RequestForm
        ref="RequestForm"
        :edit="edit"
      />
      
      <div class="RequestPage__title">
        <div class="RequestPage__title-text">
          Patient info
        </div>
        <div class="RequestPage__title-note">
          <InfoCircleOutlined class="RequestPage__title-info" />
          If you edit patient data, it will apply to all tests
        </div>
      </div>
      
      <RequestPatientForm
        ref="RequestPatientForm"
        :edit="edit"
      />
      
    </div>
    
    <div class="RequestPage__footer">
      <template v-if="edit">
        <a-button
         class="RequestPage__button"
         @click="onCancel"
        >
          Cancel
        </a-button>
        <a-button
          type="green"
          class="RequestPage__button"
          @click="onSubmit"
          :disabled="isEqual(requestInit, requestCache)"
        >
          Save
        </a-button>
      </template>
      <template v-else>
        <a-button
         class="RequestPage__button"
         @click="edit = !edit"
        >
          Edit Information
        </a-button>
      </template>
    </div>
    
  </div>
</template>
</AuthLayout>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import AuthLayout from "@/components/cms/auth.layout/AuthLayout"
import RequestForm from "@/components/cms/request/RequestForm"
import RequestPatientForm from "@/components/cms/request/RequestPatientForm"
import { CmsPatientsService } from '@/services/api_v1/cms'
import { ApiResult } from '@/models'
import { toast } from  '@/helpers/error.helper'
import _ from "lodash"

export default {
  name: "RequestPage",
  components: {
    InfoCircleOutlined,
    RequestPatientForm,
    RequestForm,
    AuthLayout,
  },
  data() {
    return {
      requestCache: null,
      edit: false,
      breadcrumbs: [
        { name: `Requests`, route: { name: `cms.requests` } },
        { name: this.$route.params.barcode },
      ],
    }
  },
  computed: {
    ...mapGetters({
      requestInit: 'CmsRequestStore/getInit',
    }),
    barcode() {
      return this.$route.params.barcode
    },
  },
  methods: {
    isEqual(obj1, obj2) { return _.isEqual(obj1, obj2) }, // lodash isEqual
    sum(v) { return _.sum(v) }, // lodash sum
    ...mapActions({
      fetchRequest: 'CmsRequestStore/fetchInit',
      requestUpdate: 'CmsRequestStore/updateById',
    }),
    ...mapMutations({
      setItemInit: 'CmsRequestStore/setItemInit',
      setItemField: 'CmsRequestStore/setItemField',
    }),
    onCancel() {
      this.edit = !this.edit
      this.setItemInit(_.cloneDeep(this.requestCache))
    },
    async onSubmit() {
      await Promise.all([
        this.$refs.RequestForm.$refs.form.validate(),
        this.$refs.RequestPatientForm.$refs.form.validate(),
      ]).then(async (responses) => {
        let request = this.requestInit.toApi(),
            patient = this.requestInit.patient.toApi()

        await Promise.all([
          this.requestUpdate({ id: request.barcode, payload: request }),
          CmsPatientsService.updateById(patient.id, patient),
        ]).then((responses) => {
          responses = responses.map(item => new ApiResult(item))
          if (responses[0].status === 200 && responses[1].status === 200) {
            toast({ type: 'success', msg: 'Update successful' })
            this.requestCache = _.cloneDeep(this.requestInit)
            this.setItemField({ id: request.id, field: 'patient', value: this.requestInit.patient })
            this.onCancel()
          }
        })
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          toast({ type: 'error', msg: error.response.data.message })
        }
      })
    },
  },
  async mounted() {
    if (this.requestInit.barcode !== this.barcode) {
      await this.fetchRequest(this.barcode)
        .then((response) => {
          this.requestCache = _.cloneDeep(this.requestInit)
        })
    } else {
        this.requestCache = _.cloneDeep(this.requestInit)
    }
  }
}
</script>

<style lang="scss" scoped>
.RequestPage {
  background-color: white;
  flex: 1;
  
  &__content {
    padding: 24px 24px 0 24px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
    
    &::-webkit-scrollbar {
        width: 13px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px 15px 15px 5px;
        background-color: rgba(0, 0, 0, 0.15);
        border-right: 8px transparent solid;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
        margin: 10px;
    }
  }
  
  &__footer {
    margin: 0 24px;
    padding: 24px 0;
    border-top: 1px solid $neutral4;
  }
  
  &__button {
    margin-right: 10px;
  }
  
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    max-width: 45.8%;
    padding-bottom: 24px;
  }
  
  &__title-note {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  
  &__title-info {
    color: $sunsetOrange6;
    margin-right: 5px;
  }
  
}
</style>