<template>
<div class="RequestPatientForm">
  
  <a-form
    ref="form"
    :model="formState"
    :rules="formRules"
    :label-col="{ span: 3 }"
    :wrapper-col="{ span: 8 }"
    validateTrigger="onSubmit"
    labelAlign="left"
    :hideRequiredMark="true"
  >
  
    <!-- Name -->
    <a-form-item
      :label="formSetup['patient.name'].label"
      name="form_patient_name"
    >
      <a-input
        v-model:value="form_patient_name"
        :disabled="formSetup['patient.name'].disabled || !edit"
        @change="onChange"
      />
    </a-form-item>
  
    <!-- Date of birth -->
    <a-form-item
      :label="formSetup['patient.dob'].label"
      name="form_patient_dob"
    >
      <a-date-picker
        :style="{ width: '100%' }"
        format="DD-MM-YYYY"
        value-format="YYYY-MM-DD[T]HH:mm:ss[.]000000[Z]"
        v-model:value="form_patient_dob"
        :disabled="formSetup['patient.dob'].disabled || !edit"
        @change="onChange"
      />
    </a-form-item>
  
    <!-- Sex -->
    <a-form-item
      :label="formSetup['patient.sex'].label"
      name="form_patient_sex"
    >
      <a-select
        v-model:value="form_patient_sex"
        :allowClear="true"
        :options="sex"
        :disabled="formSetup['patient.sex'].disabled || !edit"
        @change="onChange"
      ></a-select>
    </a-form-item>
  
    <!-- Phone -->
    <a-form-item
      :label="formSetup['patient.phone'].label"
      name="form_patient_phone"
    >
      <a-input
        v-model:value="form_patient_phone"
        :disabled="formSetup['patient.phone'].disabled || !edit"
      />
    </a-form-item>
  
    <!-- E-mail -->
    <a-form-item
      :label="formSetup['patient.email'].label"
      name="form_patient_email"
    >
      <a-input
        v-model:value="form_patient_email"
        :disabled="formSetup['patient.email'].disabled || !edit"
        @change="onChange"
      />
    </a-form-item>
  
    <!-- DGL -->
    <a-form-item
      :label="formSetup['patient.dgl'].label"
      name="form_patient_dgl"
    >
      <a-input
        v-model:value="form_patient_dgl"
        :disabled="formSetup['patient.dgl'].disabled || !edit"
        @change="onChange"
      />
    </a-form-item>
  
  </a-form>
  
</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { mapFields } from "@/helpers/form.helper"
import sex from '@/vocabulary/sex.options.json'
import _ from "lodash";

let serverErr, formSetup = {
  'patient.name': {
    label: 'Name',
    rules: [
      { max: 255, message: '' },
      { required: true, message: '' },
    ],
  },
  'patient.dob': {
    label: 'Date of birth',
    rules: [
      { required: true, message: '' },
    ],
  },
  'patient.sex': {
    label: 'Sex',
  },
  'patient.phone': {
    label: 'Phone',
    disabled: true,
  },
  'patient.email': {
    label: 'E-mail',
    rules: [
      { type: 'email', message: '' },
    ],
  },
  'patient.dgl': {
    label: 'DGL',
    rules: [
      { max: 3, message: '' },
      { required: true, message: '' },
    ],
  },
}

export default {
  name: "RequestPatientForm",
  props: {
    edit: Boolean,
  },
  data() {
    return {
      loading: false,
      formSetup,
      sex,
    }
  },
  computed: {
    ...mapFields({
      formSetup,
      props: ['fields', 'formState', 'formRules'],
      getter: (vm, field) => _.get(vm.requestInit, field),
      setter: (vm, field, value) => vm.setRequestInitField({ field, value }),
    }),
    ...mapGetters({
      requestInit: 'CmsRequestStore/getInit',
    }),
  },
  methods: {
    ...mapMutations({
      setRequestInitField: 'CmsRequestStore/setItemInitField',
    }),
    onChange() {
      this.$refs.form.clearValidate()
      serverErr = null
    },
  }
}
</script>

<style lang="scss" scoped>
.RequestPatientForm {
  //
}
</style>