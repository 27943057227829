<template>
<div class="RequestForm">
  
  <a-form
    ref="form"
    :model="formState"
    :label-col="{ span: 3 }"
    labelAlign="left"
    :wrapper-col="{ span: 8 }"
    :hideRequiredMark="true"
  >
  
    <!-- Barcode -->
    <a-form-item
      :label="formSetup.barcode.label"
      name="form_barcode"
    >
      <a-input
        v-model:value="form_barcode"
        :disabled="formSetup.barcode.disabled || !edit"
      />
    </a-form-item>
  
    <!-- Type of test -->
    <a-form-item
      :label="formSetup['test_item.title'].label"
      name="form_test_item_title"
    >
      <a-input
        v-model:value="form_test_item_title"
        :disabled="formSetup['test_item.title'].disabled || !edit"
      />
    </a-form-item>
  
    <!-- Test Taken -->
    <a-form-item
      :label="formSetup.test_taken_at.label"
      name="form_test_taken_at"
    >
      <a-date-picker
        :style="{ width: '100%' }"
        format="DD-MM-YYYY"
        value-format="YYYY-MM-DD[T]HH:mm:ss[.]000000[Z]"
        v-model:value="form_test_taken_at"
        :disabled="formSetup.test_taken_at.disabled || !edit"
      />
    </a-form-item>
    
    <!-- GC Analysis -->
    <a-form-item
      :label="formSetup.gc_analysis_at.label"
      name="form_gc_analysis_at"
    >
      <a-date-picker
        :style="{ width: '100%' }"
        format="DD-MM-YYYY"
        value-format="YYYY-MM-DD"
        v-model:value="form_gc_analysis_at"
        :disabled="formSetup.gc_analysis_at.disabled || !edit"
      />
    </a-form-item>
  
    <!-- Report Sent -->
    <a-form-item
      :label="formSetup.report_completed_at.label"
      name="form_report_completed_at"
    >
      <a-date-picker
        :style="{ width: '100%' }"
        format="DD-MM-YYYY"
        value-format="YYYY-MM-DD"
        v-model:value="form_report_completed_at"
        :disabled="formSetup.report_completed_at.disabled || !edit"
      />
    </a-form-item>
  
    <!-- Reported by -->
    <a-form-item
      :label="formSetup['conclusion_added_user.full_name'].label"
      name="form_conclusion_added_user_full_name"
    >
      <a-input
        v-model:value="form_conclusion_added_user_full_name"
        :disabled="formSetup['conclusion_added_user.full_name'].disabled || !edit"
      />
    </a-form-item>
  
    <!-- Clinical comment -->
    <a-form-item
      :label="formSetup.clinical_comments.label"
      name="form_clinical_comments"
    >
      <a-input
        v-model:value="form_clinical_comments"
        :disabled="formSetup.clinical_comments.disabled || !edit"
      />
    </a-form-item>
  
    <!-- Status -->
    <a-form-item
      :label="formSetup.status.label"
      name="form_status"
    >
      <a-select
        v-model:value="form_status"
        :options="requestStatusesFiltered"
        :disabled="formSetup.status.disabled || !edit"
      />
    </a-form-item>
  
  </a-form>
  
</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { mapFields } from "@/helpers/form.helper"
import requestStatuses from '@/vocabulary/requestStatuses.options.json'
import _ from "lodash"

let serverErr, formSetup = {
  barcode: {
    label: 'Barcode',
    disabled: true,
  },
  'test_item.title': {
    label: 'Type of test',
    disabled: true,
  },
  test_taken_at: {
    label: 'Test Taken',
    disabled: true,
  },
  gc_analysis_at: {
    label: 'GC Analysis',
    disabled: true,
  },
  report_completed_at: {
    label: 'Report Sent',
    disabled: true,
  },
  'conclusion_added_user.full_name': {
    label: 'Reported by',
    disabled: true,
  },
  clinical_comments: {
    label: 'Clinical comment',
  },
  status: {
    label: 'Status',
  },
}

export default {
  name: "RequestForm",
  props: {
    edit: Boolean,
  },
  data() {
    return {
      loading: false,
      requestStatuses,
      formSetup,
    }
  },
  computed: {
    ...mapFields({
      formSetup,
      props: ['fields', 'formState'], // 'formRules'
      getter: (vm, field) => _.get(vm.requestInit, field),
      setter: (vm, field, value) => vm.setRequestInitField({ field, value }),
    }),
    ...mapGetters({
      requestInit: 'CmsRequestStore/getInit',
    }),
    requestStatusesFiltered() {
      let filtered = new Set()
      filtered.add(this.requestInit.status)
      filtered.add("report_completed")
      return this.requestStatuses.filter((i) => filtered.has(i.value))
    },
  },
  methods: {
    ...mapMutations({
      setRequestInitField: 'CmsRequestStore/setItemInitField',
    }),
  }
}
</script>

<style lang="scss" scoped>
.RequestForm {
  //
}
</style>